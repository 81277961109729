<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: false,
    default() {
      return {}
    },
  },

  theme: {
    type: String,
    required: false,
    default: 'tertiary',
  },

  centered: {
    type: Boolean,
    required: false,
    default: false,
  },

  truncate: {
    type: Boolean,
    required: false,
    default: true,
  },

  lazy: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { formatMarkdown } = useFormatters()

const visualIsIcon = computed(() => props.content?.visual?.ext.includes('svg'))
</script>

<template>
  <article
    class="h-full rounded-2xl text-gray-600 lg:rounded-3xl"
    :class="{
      'bg-primary': theme === 'primary',
      'bg-secondary': theme === 'secondary',
      'bg-tertiary': theme === 'tertiary',
      'bg-white': theme === 'white',
      'flex flex-col items-center justify-center text-center': centered,
    }"
  >
    <div
      class="flex w-full flex-col p-6 sm:p-8"
      :class="{ 'h-full': !centered }"
    >
      <figure
        v-if="content?.visual && !visualIsIcon"
        class="relative -mx-6 -mt-6 mb-6 h-[240px] overflow-hidden rounded-t-2xl sm:-mx-8 sm:-mt-8 sm:mb-8 lg:rounded-t-3xl"
      >
        <component
          :is="content?.link?.url ? 'nuxt-link' : 'div'"
          :to="content?.link?.url"
        >
          <BaseVisual
            :file="content?.visual"
            class="absolute h-full w-full md:hidden"
            size="small"
            cover
            :lazy="lazy"
          />
          <BaseVisual
            :file="content?.visual"
            class="absolute hidden h-full w-full md:flex"
            size="medium"
            cover
            :lazy="lazy"
          />
        </component>
      </figure>

      <header
        v-if="content?.title"
        class="mb-4 flex items-center gap-x-3 sm:gap-x-5"
        :class="{ 'justify-center text-center': centered }"
      >
        <div v-if="content?.visual && visualIsIcon" class="relative h-12 w-12">
          <BaseVisual
            v-if="content?.visual"
            :file="content?.visual"
            class="relative z-10 flex h-12 w-12 items-center justify-center rounded-lg bg-primary p-2 text-white"
            :width="32"
            :height="32"
          />
        </div>

        <div :class="{ truncate: truncate, 'w-full': !centered }">
          <h2
            class="text-2xl-nlh uppercase sm:text-[40px] break-words"
            :class="{
              'text-secondary': ['primary', 'tertiary', 'white'].includes(
                theme,
              ),
              'text-white': theme === 'secondary',
              truncate: truncate,
              'text-4xl lg:text-6xl': !content?.link,
            }"
          >
            {{ content?.title }}
          </h2>
          <p
            v-if="content?.subTitle"
            class="text-sm"
            :class="{
              'text-gray-600': theme === 'primary',
              'text-gray-400': theme === 'secondary',
              'text-secondary': theme === 'tertiary' || theme === 'white',
            }"
          >
            {{ content?.subTitle }}
          </p>
        </div>
      </header>

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="content?.content"
        :class="{
          'mb-6': content?.link,
          'text-white': theme === 'secondary',
          'text-sm': content?.link,
          'lg:text-lg': !content?.link,
        }"
        v-html="formatMarkdown(content.content)"
      ></div>
      <!-- eslint-enable vue/no-v-html -->

      <footer
        v-if="content?.link"
        class="relative z-10 flex"
        :class="{ 'justify-center': centered, 'mt-auto': !centered }"
      >
        <Button
          theme="accent"
          :path="content?.link?.url"
          :aria-label="content?.link?.title"
          :text="content?.link?.title"
          class="w-full"
        >
        </Button>
      </footer>

      <slot />
    </div>
  </article>
</template>
