import type { PostListResponse } from '~/types/__generated__'

const POST_POPULATE = [
  'image',
  'category',
  'meta',
  'meta.image',
  'meta.socials',
  'meta.socials.image',
]

const CONTENT_POPULATE = [
  'content',
  'content.visual',
  'content.visuals',
  'content.buttons',
  'content.partners',
  'content.partners.logo',
  'content.link',
  'content.items',
  'content.items.icon',
  'content.items.link',
  'content.items.visual',
  'content.items.items',
  'content.items.button',
  'content.button',
  'content.posts',
  'content.posts.image',
]

export function useApiPost() {
  const route = useRoute()
  const client = useStrapiClient()
  const { getSlugFilter } = useUrl()

  async function getPosts(limit: number = 3) {
    const { data } = await client<PostListResponse>('/posts', {
      method: 'GET',
      params: {
        populate: POST_POPULATE,
        sort: 'publishedAt:desc',
        pagination: {
          page: 1,
          pageSize: limit,
        },
      },
    })
    return data
  }

  async function getPostForRoute() {
    const filters = getSlugFilter(route)

    const { data } = await client<PostListResponse>('/posts', {
      method: 'GET',
      params: {
        filters,
        populate: [...POST_POPULATE, ...CONTENT_POPULATE],
      },
    })

    return data?.[0]
  }

  return {
    getPosts,
    getPostForRoute,
  }
}
