<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },

  breadcrumbs: {
    type: Array,
    required: false,
    default() {
      return []
    },
  },

  grouped: {
    type: Boolean,
    required: false,
    default: false,
  },

  firstIsHero: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { formatMarkdown } = useFormatters()

const hasVisual = computed(() => {
  return !!props.content?.visual
})

const noContent = computed(() => {
  return !props.content?.title && !props.content?.content
})
</script>

<template>
  <section
    class="relative"
    :class="{
      'flex flex-col': !grouped,
      'min-h-[320px] sm:min-h-[480px] lg:min-h-[720px]': noContent,
      '-mt-[62px] pt-[62px] sm:-mt-[102px] md:pt-[102px]': firstIsHero,
      'bg-primary text-gray-700': content.style === 'Primary',
      'bg-secondary text-gray-100': content.style === 'Secondary',
      'bg-gray-100 text-gray-700': content.style === 'Tertiary',
      'bg-white text-gray-700': content.style === 'White',
    }"
  >
    <BaseContainer
      v-if="breadcrumbs && breadcrumbs?.length && firstIsHero"
      class="relative z-10 my-5 sm:my-8"
      :class="{ 'mb-10': grouped }"
    >
      <BaseBreadcrumbs :breadcrumbs="breadcrumbs" />
    </BaseContainer>

    <BaseContainer
      v-if="
        content?.title ||
        content?.content ||
        (content?.buttons && content?.buttons.length)
      "
      class="relative z-10 text-center"
      size="sm"
    >
      <div
        class="my-16 w-full lg:my-20"
        :class="{
          'mt-6': breadcrumbs && breadcrumbs.length,
        }"
      >
        <div>
          <header v-if="content?.title">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="markdown-heading mx-auto lg:max-w-[640px]"
              :class="{
                ' text-secondary':
                  content.style === 'Primary' ||
                  content.style === 'Tertiary' ||
                  content.style === 'White',
                'text-accent': content.style === 'Secondary',
              }"
              v-html="
                formatMarkdown(
                  firstIsHero ? `# ${content?.title}` : `## ${content?.title}`,
                )
              "
            ></div>
            <!-- eslint-enable vue/no-v-html -->
          </header>

          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="content?.content"
            class="markdown prose mb-8 mt-4 font-medium lg:text-lg"
            :class="{
              'prose-secondary': content.style === 'Secondary',
            }"
            v-html="formatMarkdown(content.content)"
          ></div>
          <!-- eslint-enable vue/no-v-html -->

          <footer
            v-if="content?.buttons && content?.buttons.length"
            class="relative z-10 flex flex-wrap justify-center gap-3 !gap-y-2 sm:gap-5"
          >
            <Button
              v-for="button in content.buttons"
              :key="button.id"
              :theme="button?.style.toLowerCase() || 'primary'"
              :path="button?.url"
              :aria-label="button?.title"
              :text="button?.title"
            />
          </footer>
        </div>
      </div>
    </BaseContainer>

    <BaseVisual
      v-if="hasVisual"
      :file="content?.visual"
      class="absolute right-0 top-0 h-full w-full object-cover"
      :class="{ 'opacity-10': !noContent }"
      fetchpriority="high"
      size="large"
      :lazy="false"
      cover
    />

    <div
      v-if="noContent && firstIsHero"
      class="pointer-events-none absolute right-0 top-0 z-10 h-[240px] w-full bg-gradient-to-b to-transparent"
      :class="{
        'from-primary': content.style === 'Primary',
        'from-secondary': content.style === 'Secondary',
        'from-gray-100 ': content.style === 'Tertiary',
        'from-white': content.style === 'White',
      }"
    ></div>
  </section>
</template>
